import React from "react"
import { useStaticQuery, graphql } from "gatsby";
import { heroResolveRichText } from "../../../utils/resolve-rich-text/heroResolveRichText";
import { TextMono, Button } from "@atoms"
import OptimizedImage from "../../../utils/optimizedImage";
import Breadcrumbs from "../../Hero/components/Breadcrumbs"
import { IntegrationHeroWrap, BreadcrumbContainer, Container, TextContentWrapper, BtnWrapper, Heading, Subheading } from "../styles/IntegrationsHero.styled";

const IntegrationsHero = ({ component, location }) => {
  const backgroundData = useStaticQuery(graphql`
    query IntegrationHeroBackgroundQuery {
      contentfulComponentImage(id: {eq: "1fbd679a-cf7e-5bd2-84b1-0e5591a6f000"}) {
        __typename
        id
        primaryImage {
          gatsbyImageData
          file {
            url
          }
          description
        }
      }
    }
  `)

  const {
    heading,
    subheading,
    kicker,
    callsToAction,
    breadcrumbs,
  } = component

  const backgroundImage = backgroundData?.contentfulComponentImage?.primaryImage
  const kickerColor= "#69D1F0"
  
  return (
    <IntegrationHeroWrap
      className="flex"
    >
      {backgroundImage &&
        <OptimizedImage className="background-image" image={backgroundImage.gatsbyImageData} src={backgroundImage?.file?.url} alt="blue background image" loading="eager"/>
      }
      {breadcrumbs && 
        <BreadcrumbContainer>
          <Breadcrumbs location={location}/>
        </BreadcrumbContainer>
      }
      <Container>
        <TextContentWrapper>
          {kicker && (
            <TextMono
              style={{
                color: kickerColor,
                fontSize: "14px"
              }}
            >
              {kicker}
            </TextMono>
          )}
          {heading && (
            <Heading>
              {heading}
            </Heading>
          )}
          {subheading && (
            <Subheading 
              style={{
                color: "white"
              }}>
              {heroResolveRichText(component)}
            </Subheading>
          )}
          {callsToAction &&
            <BtnWrapper>
              {callsToAction[0]?.link &&
              callsToAction.map(btn => (
                <Button
                  ctaVariant={btn?.variant || "primary"}
                  ctaLink={btn?.link}
                >
                  {btn?.label}
                </Button>
              ))}
            </BtnWrapper>
          }
        </TextContentWrapper>
      </Container>
    </IntegrationHeroWrap>
  )
}

export default IntegrationsHero
